import React, { Fragment, useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { cloneDeep } from 'lodash';
import * as FileSaver from 'file-saver';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Translate, I18n } from 'react-redux-i18n';
import { Table, Button, Col, FormGroup, FormControl, ControlLabel, Form, Row, Alert, Checkbox } from 'react-bootstrap';

//import { actionCreators as actionCreatorsLog } from '../../store/Log';
import { actionCreators as actionMedstatTemplates} from '../../store/Medstat/Templates';
import { actionCreators as actionCreatorsNsi } from '../../store/Nsi';

import { getCurrentYear, years, getNameLocalize } from '../Constants';


const EditTemplateFiles = (props) => {
    useState(() => {
        if (props.nsi.sprform.length === 0) props.getNs('sprform');
    });
    const [kf, setKf] = useState("01");
    const [year, setYear] = useState(getCurrentYear());
    const [selectedTemplate, setSelectedTemplate]= useState()

    const onLoadTemplates = (e) => {
        e.preventDefault();
        e.target.value = null;
        //let inputRu = document.getElementById('fileTemplateMedstat');
        //let inputKz = document.getElementById('fileTemplateMedstatKz');
        //inputRu.value = null;
        //inputKz.value = null;
        const fetchData = async () => {
            let data = await props.LoadTemplates(year, kf);
            let form = props.nsi.sprform.filter(f => f.kf === kf)[0];
            setSelectedTemplate({ form, ...data});
        }
        fetchData();
    }
    const uploadExcel = async (lang) => {
        let input = document.getElementById(lang == "ru" ? 'fileTemplateMedstat' : 'fileTemplateMedstatKz');
        if (input.files.length === 0) {
            alert("Выберите файл");
            return;
        }
        let formData = new FormData();
        formData.append("year", year);
        formData.append("kf", kf);
        formData.append("lang", kf);
        formData.append("fileExcel", input.files[0]);
        var ret = await props.UploadTemplate(formData, year, kf, lang);
        let form = props.nsi.sprform.filter(f => f.kf === kf)[0];
        setSelectedTemplate({ form, ...ret });
    }
    const uploadExcelRu = (e) => {
        e.preventDefault();
        uploadExcel("ru");
    }
    const uploadExcelKz = (e) => {
        e.preventDefault();
        uploadExcel("kk");
    }
    const downloadExcel = async (lang) => {
        const blob = await props.DownloadTemplate(year, kf, lang);
        FileSaver.saveAs(blob, lang = "ru" ? selectedTemplate.fileName : selectedTemplate.fileNameKz );
    }
    const downloadExcelRu = (e) => {
        e.preventDefault();
        downloadExcel("ru");
    }
    const downloadExcelKz = (e) => {
        e.preventDefault();
        downloadExcel("kk");
    }
    let ac = props.userInfo.user.accessLevel;
    if (ac != 1 && props.userInfo.user.roles.filter(r => r === 'AdminEditData').length === 0) return <div className="text-danger"><Translate value="noAccess" /></div>;
    return <Fragment><Form onSubmit={onLoadTemplates} horizontal>
        <h5 style={{ textAlign: "center" }}><strong><Translate value='templateExcel.title' /></strong></h5>
        <FormGroup controlId="formYear" bsSize='small'>
            <Col xs={12} sm={2} className="text-right"><ControlLabel><Translate value='year' />{':'}</ControlLabel></Col>
            <Col xs={12} sm={10}>
                <FormControl className='input-sm' componentClass="select" placeholder="select"
                    defaultValue={year} onChange={(e) => setYear(parseInt(e.target.value, 10))} disabled={props.medstatTemplates.isLoading === true}>
                    {years.filter(f => f > 2016).map(it => <option key={it} value={it}>{it}</option>)}
                </FormControl></Col>
        </FormGroup>
        <FormGroup controlId="formKf" bsSize='small'>
            <Col xs={12} sm={2} className="text-right"><ControlLabel><Translate value='forma' />{':'}</ControlLabel></Col>
            <Col xs={12} sm={10}><FormControl className='input-sm' componentClass="select" placeholder="select" defaultValue={kf}
                onChange={(e) => setKf(e.target.value)} style={{ minWidth: '100%' }} disabled={props.medstatTemplates.isLoading === true}>
                {props.nsi.sprform.filter(f => f.kf != "70" && f.kf != "71" && f.kf != "72" && f.kf != "73" && f.kf != "74").map(it => <option key={it.kf} value={it.kf}>{it.kf} {it.nameRu}</option>)}
            </FormControl></Col>
        </FormGroup>
        <FormGroup bsSize='small'>
            <Col smOffset={2} sm={10}>
                <Button type="submit" bsStyle="primary" bsSize="large" disabled={props.medstatTemplates.isLoading === true}><Translate value="templateExcel.load" /></Button>
            </Col>
        </FormGroup>
    </Form>
        {selectedTemplate != null ? <div style={{ border: "1px solid lightgray", padding: "5px", margin: "5px"}}>
            <Row><Col sm={6} smOffset={4}><strong><Translate value='forma' />{':'}</strong>{selectedTemplate.form.kf} {getNameLocalize(props.i18n, selectedTemplate.form)}</Col></Row>
            <Row><Col sm={6}>
                <FormGroup controlId="fileTemplateMedstat" className='input-group-sm'>
                    <Col xs={12} sm={5} className="text-right"><ControlLabel>Шаблон Excel(рус){':'}</ControlLabel></Col>
                    <Col xs={12} sm={7}>
                        <FormControl disabled={props.medstatTemplates.isLoading === true}
                            type="file"
                            label="File" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                            help="Выберите щаблон Excel" /><Button onClick={uploadExcelRu}>Загрузить шаблон на сервер</Button>
                        {selectedTemplate != null && selectedTemplate.fileName != null ? <Button onClick={downloadExcelRu}>Скачать шаблон с сервера {selectedTemplate.fileName}</Button> : ''}
                    </Col>
                </FormGroup></Col>
                <Col sm={6}>
                    <FormGroup controlId="fileTemplateMedstatKz" className='input-group-sm'>
                        <Col xs={12} sm={5} className="text-right"><ControlLabel>Шаблон Excel(каз){':'}</ControlLabel></Col>
                        <Col xs={12} sm={7}>
                            <FormControl disabled={props.medstatTemplates.isLoading === true}
                                type="file"
                                label="File" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                help="Выберите щаблон Excel" /><Button onClick={uploadExcelKz}>Загрузить шаблон на сервер</Button>
                            {selectedTemplate != null && selectedTemplate.fileNameKz != null ? <Button onClick={downloadExcelKz}>Скачать шаблон с сервера {selectedTemplate.fileNameKz}</Button> : ''}
                        </Col>
                    </FormGroup></Col>
            </Row>
        </div> : ""}
    </Fragment>;
}
export default connect(
    state => state,
    dispatch => bindActionCreators({...actionCreatorsNsi, ...actionMedstatTemplates }, dispatch)
)(EditTemplateFiles);
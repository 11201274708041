import { cloneDeep} from 'lodash';
import { getToken } from './UserInfo';
const requestPaidDataType = 'REQUEST_PAID_DATA';
const receivePaidDataType = 'RECEIVE_PAID_DATA';
const requestGetObjectsDataType = 'REQUEST_GET_OBJECTS_DATA';
const receiveGetObjectsDataType = 'RECEIVE_GET_OBJECTS_DATA';
const requestAddDogovorType = 'REQUEST_ADD_DOGOVOR';
const receiveAddDogovorType = 'RECEIVE_ADD_DOGOVOR';
const requestUpdateDogovorType = 'REQUEST_UPDATE_DOGOVOR';
const receiveUpdateDogovorType = 'RECEIVE_UPDATE_DOGOVOR';
const requestDeleteDogovorType = 'REQUEST_DELETE_DOGOVOR';
const receiveDeleteDogovorType = 'RECEIVE_DELETE_DOGOVOR';
const requestAddBillType = 'REQUEST_ADD_BILL';
const receiveAddBillType = 'RECEIVE_ADD_BILL';
const requestUpdateBillType = 'REQUEST_UPDATE_BILL';
const receiveUpdateBillType = 'RECEIVE_UPDATE_BILL';
const requestDeleteBillType = 'REQUEST_DELETE_BILL';
const receiveDeleteBillType = 'RECEIVE_DELETE_BILL';
const requestGetDogovorsType = 'REQUEST_GET_DOGOVORS';
const receiveGetDogovorsType = 'RECEIVE_GET_DOGOVORS';
const requestGetReport1Type = 'REQUEST_GET_REPORT1';
const receiveGetReport1Type = 'RECEIVE_GET_REPORT1';
//const requestSetPaidOrg = 'REQUEST_SET_PAID_ORG';
const receiveSetPaidOrg = 'RECEIVE_SET_PAID_ORG';
const receiveSetPaidTer = 'RECEIVE_SET_PAID_TER';
const receiveSetIsPrivateOrg = 'RECEIVE_SET_IS_PRIVATE_ORG';
const receiveSetIs50persOrg = 'RECEIVE_SET_IS_50_PER_ORG';
const receivePaidFormsOrg = 'RECEIVE_PAID_FORMS_ORG';

const initialState = { paids: [], isLoading: false, obl_paid: undefined, obj_data: undefined, dogovors: [], rep1data: undefined };

export const actionCreators = {
    getReport1: year => async (dispatch, getState) => {
        var token = await getToken(dispatch, getState().userInfo);
        if (token === undefined) return;
        dispatch({ type: requestGetReport1Type });
        const url = `api/FinPaidApi/report1?year=${year}`;
        const response = await fetch(url, {
            credentials: 'include',
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        });
        const rep1data = await response.json();
        dispatch({ type: receiveGetReport1Type, rep1data });
    },
    setPaidOrg: (year, org, quarter, ispaid) => async (dispatch, getState) => {
        var token = await getToken(dispatch, getState().userInfo);
        if (token === undefined) return;
        //dispatch({ type: requestSetPaidOrg });
        const url = `api/FinPaidApi/setpaidorg?year=${year}&org=${org}&quarter=${quarter}&ispaid=${ispaid}`;

        /*const response =*/ await fetch(url, {
            credentials: 'include',
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        });
        //const paids = await response.json();

        dispatch({ type: receiveSetPaidOrg, objFin: {year, org, quarter, ispaid} });
    },
    setPaidFormsOrg: (year, org, forms) => (dispatch, getState) => {
        dispatch({
            type: receivePaidFormsOrg,
            objForms: { year, org, forms}
        });
    }, 
    setIsPrivateOrg: (org, isPrivate) => async (dispatch, getState) => {
        var token = await getToken(dispatch, getState().userInfo);
        if (token === undefined) return;
        //dispatch({ type: requestSetPaidOrg });
        const url = `api/FinPaidApi/setIsPrivateOrg?org=${org}&isPrivate=${isPrivate}`;

        /*const response =*/ await fetch(url, {
            credentials: 'include',
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        });
        //const paids = await response.json();

        dispatch({ type: receiveSetIsPrivateOrg, objFin: { org, isPrivate } });
    },
    setIs50persOrg: (org, year, is50pers) => async (dispatch, getState) => {
        var token = await getToken(dispatch, getState().userInfo);
        if (token === undefined) return;
        const url = `api/FinPaidApi/setIs50PersOrg?org=${org}&year=${year}&is50pers=${is50pers}`;
        await fetch(url, {
            credentials: 'include',
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        });
        dispatch({ type: receiveSetIs50persOrg, objFin: { org, is50pers } });
    },
    setPaidTer: (year, ter, quarter, ispaid) => async (dispatch, getState) => {
        var token = await getToken(dispatch, getState().userInfo);
        if (token === undefined) return;
        //dispatch({ type: requestSetPaidType });
        const url = `api/FinPaidApi/setpaidter?year=${year}&ter=${ter}&quarter=${quarter}&ispaid=${ispaid}`;

        /*const response =*/ await fetch(url, {
            credentials: 'include',
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        });
        //const paids = await response.json();

        dispatch({ type: receiveSetPaidOrg, objFin: { year, org: ter, quarter, ispaid } });
    },

    getOrgsPaid: (obl, year) => async (dispatch, getState) => {
        var token = await getToken(dispatch, getState().userInfo);
        if (token === undefined) return;
        dispatch({ type: requestPaidDataType });
        const url = `api/FinPaidApi/getorgspaid?obl_id=${obl}&year=${year}`;

        const response = await fetch(url, {
            credentials: 'include',
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        });
        const paids = await response.json();

        dispatch({ type: receivePaidDataType, obl, paids });
    },
    getAllObjects: () => async (dispatch, getState) => {
        if (getState().finPaidData.obj_data !== undefined) {
            return;
        }
        var token = await getToken(dispatch, getState().userInfo);
        if (token === undefined) return;
        dispatch({ type: requestGetObjectsDataType});
        const url = `api/FinPaidApi/allobjects`;

        const response = await fetch(url, {
            credentials: 'include',
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        });
        const obj_data = await response.json();

        dispatch({ type: receiveGetObjectsDataType, obj_data });
    },
    AddDogovor: (dogovor) => async (dispatch, getState) => {
        var token = await getToken(dispatch, getState().userInfo);
        if (token === undefined) return;
        dispatch({ type: requestAddDogovorType });
        let json = JSON.stringify(dogovor);
        const url = 'api/FinPaidApi/AddDogovor';
        const response = await fetch(url, {
            credentials: 'include',
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: json
        });
        const status = await response.json();
        dispatch({ type: receiveAddDogovorType, status});
    },
    UpdateDogovor: (dogovor) => async (dispatch, getState) => {
        var token = await getToken(dispatch, getState().userInfo);
        if (token === undefined) return;
        dispatch({ type: requestUpdateDogovorType });
        let json = JSON.stringify(dogovor);
        const url = 'api/FinPaidApi/UpdateDogovor';
        const response = await fetch(url, {
            credentials: 'include',
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: json
        });
        const status = await response.json();
        dispatch({ type: receiveUpdateDogovorType, status });
    },
    DeleteDogovor: (id) => async (dispatch, getState) => {
        var token = await getToken(dispatch, getState().userInfo);
        if (token === undefined) return;
        dispatch({ type: requestDeleteDogovorType });
        const url = `api/FinPaidApi/DeleteDogovor?id=${id}`;

        const response = await fetch(url, {
            credentials: 'include',
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        });
        const status = await response.json();
        dispatch({ type: receiveDeleteDogovorType, status });
    },
    AddBill: (bill, fnCallback) => async (dispatch, getState) => {
        var token = await getToken(dispatch, getState().userInfo);
        if (token === undefined) return;
        dispatch({ type: requestAddBillType });
        let json = JSON.stringify(bill);
        const url = 'api/FinPaidApi/AddBill';
        const response = await fetch(url, {
            credentials: 'include',
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: json
        });
        const status = await response.json();
        dispatch({ type: receiveAddBillType, status, fnCallback });
    },
    UpdateBill: (bill, fnCallback) => async (dispatch, getState) => {
        var token = await getToken(dispatch, getState().userInfo);
        if (token === undefined) return;
        dispatch({ type: requestUpdateBillType });
        let json = JSON.stringify(bill);
        const url = 'api/FinPaidApi/UpdateBill';
        const response = await fetch(url, {
            credentials: 'include',
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: json
        });
        const status = await response.json();
        dispatch({ type: receiveUpdateBillType, status, fnCallback });
    },
    DeleteBill: (id, fnCallback) => async (dispatch, getState) => {
        var token = await getToken(dispatch, getState().userInfo);
        if (token === undefined) return;
        dispatch({ type: requestDeleteBillType });
        const url = `api/FinPaidApi/DeleteBill?id=${id}`;

        const response = await fetch(url, {
            credentials: 'include',
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        });
        const status = await response.json();
        dispatch({ type: receiveDeleteBillType, status, fnCallback });
    },
    getDogovors: (params) => async (dispatch, getState) => {
        var token = await getToken(dispatch, getState().userInfo);
        if (token === undefined) return;
        dispatch({ type: requestGetDogovorsType });
        const url = `api/FinPaidApi/GetDogovors?typeObj=${params.typeObj}&kodObj=${params.kodObj}`;

        const response = await fetch(url, {
            credentials: 'include',
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        });
        const status = await response.json();
        dispatch({ type: receiveGetDogovorsType, status });
    },
    setFormsByOrg: (params) => async (dispatch, getState) => {
        var token = await getToken(dispatch, getState().userInfo);
        if (token === undefined) return;
        let json = JSON.stringify(params);
        const url = 'api/FinPaidApi/SetFormsByOrg';
        const response = await fetch(url, {
            credentials: 'include',
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: json
        });
        if (response.ok) { // если HTTP-статус в диапазоне 200-299
            // получаем тело ответа (см. про этот метод ниже)
            return await response.json();
        } else {
            return { successed: false, error: `Ошибка HTTP: ${response.status}` }
        }
    },
    getFormsByOrg: (kodOrg, year) => async (dispatch, getState) => {
        var token = await getToken(dispatch, getState().userInfo);
        if (token === undefined) return;
        const url = `api/FinPaidApi/GetFormsByOrg?year=${year}&kodOrg=${kodOrg}`;
        const response = await fetch(url, {
            credentials: 'include',
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        });
        if (response.ok) { // если HTTP-статус в диапазоне 200-299
            // получаем тело ответа (см. про этот метод ниже)
            return await response.json();
        } else {
            return { successed: false, error: `Ошибка HTTP: ${response.status}` }
        }
    }
};

export const reducer = (state, action) => {
    state = state || initialState;

    if (action.type === requestPaidDataType) {
        return {
            ...state,
            paids:[],
            isLoading: true
        };
    }

    if (action.type === receivePaidDataType) {
        return {
            ...state,
            obl_paid: action.obl,
            paids: action.paids,
            isLoading: false
        };
    }
    if (action.type === requestGetObjectsDataType) {
        return {
            ...state,
            isLoading: true
        };
    }
    if (action.type === receiveGetObjectsDataType) {
        return {
            ...state,
            obj_data: action.obj_data,
            isLoading: false
        };
    }
    if (action.type === requestAddDogovorType) {
        return {
            ...state,
            isLoading: true
        };
    }
    if (action.type === receiveAddDogovorType) {
        if (action.status.successed === true) {
            let dogovors = cloneDeep(state.dogovors);//JSON.parse(JSON.stringify(state.dogovors));//Object.assign(state.dogovors);
            dogovors.push({ ...action.status.dogovor });
            return {
                ...state,
                isLoading: false,
                dogovors
            };
        } else
            return {
                ...state,
                isLoading: false,
            };
    }
    if (action.type === requestUpdateDogovorType) {
        return {
            ...state,
            isLoading: true
        };
    }
    if (action.type === receiveUpdateDogovorType) {
        if (action.status.successed === true) {
            let dogovors = cloneDeep(state.dogovors);//JSON.parse(JSON.stringify(state.dogovors));//Object.assign(state.dogovors);
            let index = dogovors.map(e => e.id).indexOf(action.status.dogovor.id);
            dogovors.splice(index, 1, { ...action.status.dogovor, dt: new Date(action.status.dogovor.dt), srokOt: new Date(action.status.dogovor.srokOt), srokDo: new Date(action.status.dogovor.srokDo)});
            return {
                ...state,
                isLoading: false,
                dogovors: dogovors
            };
        } else
            return {
                ...state,
                isLoading: false,
            };
    }
    if (action.type === requestDeleteDogovorType) {
        return {
            ...state,
            isLoading: true
        };
    }
    if (action.type === receiveDeleteDogovorType) {
        if (action.status.successed === true) {
            let dogovors = cloneDeep(state.dogovors);//JSON.parse(JSON.stringify(state.dogovors));//Object.assign(state.dogovors);
            let ind = dogovors.map(e => e.id).indexOf(action.status.id);
            dogovors.splice(ind, 1);
            return {
                ...state,
                isLoading: false,
                dogovors: dogovors
            };
        } else
            return {
                ...state,
                isLoading: false,
            };
    }
    if (action.type === requestAddBillType) {
        return {
            ...state,
            isLoading: true
        };
    }
    if (action.type === receiveAddBillType) {
        if (action.status.successed === true) {
            let dogovors = cloneDeep(state.dogovors);//JSON.parse(JSON.stringify(state.dogovors));//Object.assign(state.dogovors);
            let dog = dogovors.filter(d => d.id === action.status.bill.dogovorId)[0];
            dog.bills.push({ ...action.status.bill, dtFrom: new Date(action.status.bill.dtFrom), dtTo: new Date(action.status.bill.dtTo) });
            if (action.fnCallback instanceof Function) {
                action.fnCallback(dog);
            }
            return {
                ...state,
                isLoading: false,
                dogovors
            };
        } else
            return {
                ...state,
                isLoading: false,
            };
    }
    if (action.type === requestUpdateBillType) {
        return {
            ...state,
            isLoading: true
        };
    }
    if (action.type === receiveUpdateBillType) {
        if (action.status.successed === true) {
            let dogs = cloneDeep(state.dogovors);//JSON.parse(JSON.stringify(state.dogovors));//Object.assign(state.dogovors);
            let ind = dogs.map(e => e.id).indexOf(action.status.bill.dogovorId);
            let dg = cloneDeep(dogs[ind]);
            let indx = dg.bills.map(e => e.id).indexOf(action.status.bill.id);
            dg.bills = cloneDeep(dg.bills);
            dg.bills.splice(indx, 1, { ...action.status.bill, dtFrom: new Date(action.status.bill.dtFrom), dtTo: new Date(action.status.bill.dtTo) });
            dogs.splice(ind, 1, dg);
            if (action.fnCallback instanceof Function) {
                action.fnCallback(dg);
            }
            return {
                ...state,
                isLoading: false,
                dogovors: dogs
            };
        } else
            return {
                ...state,
                isLoading: false,
            };
    }
    if (action.type === requestDeleteBillType) {
        return {
            ...state,
            isLoading: true
        };
    }
    if (action.type === receiveDeleteBillType) {
        if (action.status.successed === true) {
            let dogovors = cloneDeep(state.dogovors);//JSON.parse(JSON.stringify(state.dogovors));//Object.assign(state.dogovors);
            let ind = dogovors.map(e => e.id).indexOf(action.status.dogovorId);
            let dg = cloneDeep(dogovors[ind]);
            let indx = dg.bills.map(e => e.id).indexOf(action.status.id);
            dg.bills = cloneDeep(dg.bills);
            dg.bills.splice(indx, 1);
            dogovors.splice(ind, 1, dg);
            if (action.fnCallback instanceof Function) {
                action.fnCallback(dg);
            }
            return {
                ...state,
                isLoading: false,
                dogovors: dogovors
            };
        } else
            return {
                ...state,
                isLoading: false,
            };
    }
    if (action.type === requestGetDogovorsType) {
        return {
            ...state,
            dogovors:[],
            isLoading: true
        };
    }
    if (action.type === receiveGetDogovorsType) {
        if (action.status.successed === true) {
            return {
                ...state,
                isLoading: false,
                dogovors: action.status.dogovors.map(it => { return { ...it, dt: new Date(it.dt), srokOt: new Date(it.srokOt), srokDo: new Date(it.srokDo), bills: it.bills.map(b => { return { ...b, dtFrom: new Date(b.dtFrom), dtTo: new Date(b.dtTo) }; }) } })
            };
        } else
            return {
                ...state,
                isLoading: false,
            };
    }
    if (action.type === requestGetReport1Type) {
        return {
            ...state,
            rep1data: undefined,
            isLoading: true
        };
    }
    if (action.type === receiveGetReport1Type) {
            return {
                ...state,
                isLoading: false,
                rep1data: action.rep1data
            };
    }
   
    if (action.type === receiveSetPaidOrg) {
        let paids = cloneDeep(state.paids);
        var sel = paids.objsItems.filter(f => f.obj.kod === action.objFin.org)[0];
        sel[`quarter${action.objFin.quarter}`] = action.objFin.ispaid;
        return {
            ...state,
            paids
        };
    }
    if (action.type === receivePaidFormsOrg) {
        let paids = cloneDeep(state.paids);
        var sel = paids.objsItems.filter(f => f.obj.kod === action.objForms.org)[0];
        sel.forms = action.objForms.forms.join(',');
        return {
            ...state,
            paids
        };
    }
    if (action.type === receiveSetIsPrivateOrg) {
        let paids = cloneDeep(state.paids);
        /*let ind = paids.objsItems.map(f => f.obj.kod).indexOf(action.objFin.org);
        let sel = cloneDeep(paids.objsItems[ind]);
        sel.isPrivateOrg = action.objFin.isPrivate;
        paids.objsItems.splice(ind, 1, sel);
        */
        var sel = paids.objsItems.filter(f => f.obj.kod === action.objFin.org)[0];
        sel.obj.isPrivateOrg = action.objFin.isPrivate;
        return {
            ...state,
            paids
        };
    }
    if (action.type === receiveSetIs50persOrg){
        let p = cloneDeep(state.paids);
        let ind = p.objsItems.map(f => f.obj.kod).indexOf(action.objFin.org);
        let sel = cloneDeep(p.objsItems[ind]);
        sel.is50pers = action.objFin.is50pers;
        if (action.objFin.is50pers === 1) {
            sel.quarter1 = true;
            sel.quarter2 = true;
        }
        p.objsItems.splice(ind, 1, sel);
        return {
            ...state,
            paids:p
        };
    }
    if (action.type === receiveSetPaidTer) {
        let paids = cloneDeep(status.paids);//paids.objsItems[].obj.kod===
        //action.objFin;//objFin: {year, org, quarter, ispaid} }
        return {
            ...state,
            paids
        };
    }
    return state;
};
import React, { Fragment, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { cloneDeep } from 'lodash';
import * as FileSaver from 'file-saver';
import { Translate, I18n } from 'react-redux-i18n';
import { Button, Col, FormGroup, FormControl, ControlLabel, Form, Checkbox, Radio, HelpBlock, Row, Alert, Modal } from 'react-bootstrap';

import { actionCreators as actionCreatorsSvod } from '../../store/Svod';
import { actionCreators as actionCreatorsNsi } from '../../store/Nsi';
//import SelectMultiObjects from '../common/SelectMultiObjects';
//import SelectMultiForms from '../common/SelectMultiForms';
//import SelectMultiForms from '../common/SelectMultiForms';
//import SelectMultiObjects from '../common/SelectMultiObjects';
import SelectObject from '../common/SelectObject'

import FilterRazOptions from '../common/FilterRazOptions';
import { years, getCurrentYear, getNameLocalize } from '../Constants';

const SvodFormMonths = (props) => {
    useState(() => {
        if (props.nsi.periods.length === 0) props.getNs('periods');
        if (props.nsi.allobls.length === 0) props.getNs('allobls');
        if (props.nsi.sprraz.length === 0) props.getNs('sprraz');
        if (props.nsi.sprform.length === 0) props.getNs('sprform');
        if (props.nsi.allraions.length === 0) props.getNs('allraions');
        if (props.userInfo.user.nomenklKod === '008' || props.userInfo.user.nomenklKod === '049' || props.userInfo.user.nomenklKod === '043') {
            const fetchData = async () => {
                const chOrgs = await props.getSubOrgs();
                setChildOrgs(chOrgs);
            }
            fetchData();
        }
    });
    const [year, setYear] = useState(() => getCurrentYear());
    const [kpers, setKpers] = useState([]);
    const [krazSrc, setKrazSrc] = useState(1);
    const [tobj, setTobj] = useState(props.userInfo.user.accessLevel); // тип обьекта 1 – РК; 2 – область; 3 - район; 4 - организация (доступ зависит от пользователя)
    const [obl, setObl] = useState();
    const [raion, setRaion] = useState();
    const [org, setOrg] = useState();
    const [kf, setKf] = useState("56");
    const [errors, setErrors] = useState([]);
    const [successMessage, setSuccessMessage] = useState();
    const [svodRunning, setSvodRunning] = useState(false);
    const setParam = (param, value, checked) => {
        //setSelOrgs([]);
        //setOrgs([]);
        switch (param) {
            case "year":
                setYear(value);
                break;
            case "kpers":
                //setKper(value);
                let pers = cloneDeep(kpers);
                if (checked == true) {
                    pers.push(value);
                } else {
                    let ind = pers.indexOf(value);
                    pers.splice(ind, 1);
                }
                setKpers(pers);
                break;
            case "krazSrc":
                setKrazSrc(value);
                break;
            case "kf":
                setKf(value);
                break;
            default:
        }
    }
    const getSvodParams = (fnErr) => {
        setErrors([]);
        setSuccessMessage(null);
        let errs = [];
        let kobjs = [];
        switch (tobj) {
            case 1:
                kobjs = ["99"];
                break;
            case 2:
                if (props.userInfo.user.accessLevel === 2)
                    kobjs = [props.userInfo.user.kodObl];
                else
                    kobjs = [obl.kobl];
                break;
            case 3:
                if (props.userInfo.user.accessLevel === 3)
                    kobjs = [props.userInfo.user.kodRaion];
                else
                    kobjs = [raion.kraion];
                break;
            case 4:
                if (props.userInfo.user.accessLevel === 4)
                    kobjs = [props.userInfo.user.kodOrg];
                else
                    kobjs = [org.korg];
                break;
            default:
        }
        if (kf == null || kf==="") {
            errs.push("selectForm");
        }
        if (kpers.length===0) {
            errs.push("selectMonths");
        }
        if (errs.length > 0)
            fnErr(errs);
        return {
            year,
            kpers,
            kraz:krazSrc,
            tobj,
            kobjs,
            kf
        };
    }
    const onSvodFormQuarts = (e) => {
        e.preventDefault();
        const svodMonths = async () => {
            let errs = [];
            let param = getSvodParams((ers) => errs = ers);
            if (errs.length > 0) {
                setErrors(errs);
                return;
            };
            setSvodRunning(true);
            var ret = await props.existsSvodFormMonths(param);
            if (ret.successed === false) {
                setErrors([ret.error]);
            } else {
                if (ret.isExists === true) {
                    //generate Excel
                    let absoluteUrl = `${document.location.protocol}//${document.location.host}/generate/Excel/SvodFormMonths`;
                    let fileName = `months_${param.year}_${param.kraz}_${param.kf}_p${param.kpers.join("_")}_t${param.tobj}_o${param.kobjs.join("_")}.xlsx`;
                    let json = JSON.stringify(param);
                    const response = await fetch(absoluteUrl, {
                        credentials: 'include',
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${props.userInfo.user.token}`
                        },
                        body: json
                    });
                    if (response.ok) { // если HTTP-статус в диапазоне 200-299
                        // получаем тело ответа (см. про этот метод ниже)
                        const blob = await response.blob();
                        FileSaver.saveAs(blob, fileName);
                        props.setIsLoading(false);
                    } else {
                        alert("Ошибка HTTP: " + response.status);
                        props.setIsLoading(false);
                    }
                } else {
                    setSuccessMessage(I18n.t("noDataForm"));
                }
            }
            setSvodRunning(false);
        }
        svodMonths();
    }
    let isReadOnLy = props.userInfo.user.roles.filter(r => r === 'UserReadData').length > 0;
    if (isReadOnLy === true)
        return <Translate value="noAccess" />
    else
        return <><Form onSubmit={onSvodFormQuarts} horizontal>
            <h5 style={{ textAlign: "center" }}><strong><Translate value='svodFormMonths' /></strong></h5>
            <FormGroup controlId="formYear" bsSize='small'>
                <Col sm={2} componentClass={ControlLabel}><Translate value='year' />{':'}</Col>
                <Col sm={10}>
                    <FormControl className='input-sm' componentClass="select" placeholder="select" disabled={props.svod.isLoading}
                        defaultValue={year} onChange={(e) => setParam("year", parseInt(e.target.value, 10))} bsSize="small" style={{ width: "auto" }}>
                        {years.map(it => <option key={it} value={it}>{it}</option>)}
                    </FormControl></Col>
            </FormGroup>
            <FormGroup bsSize='small'>
                <Col sm={2} componentClass={ControlLabel}><Translate value='months2' />{':'}</Col>
                <Col sm={10}>
                    {props.nsi.periods.filter(f => f.kper > 100).map(it => <Checkbox key={it.kper} inline value={it.kper} checked={kpers.includes(it.kper)}
                        onChange={(e) => setParam("kpers", parseInt(e.target.value, 10), e.target.checked)}
                        disabled={props.svod.isLoading} name="groupMonths">{getNameLocalize(props.i18n, it)}</Checkbox>)}
                </Col>
            </FormGroup>
            <FormGroup controlId="formRaz" bsSize='small'>
                <Col sm={2} componentClass={ControlLabel}><Translate value='razSourceOne' />{':'}</Col>
                <Col sm={10}><FormControl className='input-sm' componentClass="select" placeholder="select" defaultValue={krazSrc} disabled={props.svod.isLoading}
                    onChange={(e) => setParam("krazSrc", parseInt(e.target.value, 10))} bsSize='small' style={{ width: "auto" }}>
                    <FilterRazOptions />
                </FormControl></Col>
            </FormGroup>
            <SelectObject
                tobj={tobj}
                obl={obl}
                raion={raion}
                org={org}
                setTobj={setTobj}
                setObl={setObl}
                setRaion={setRaion}
                setOrg={setOrg} disabled={svodRunning}
                year={year}
            />
            <FormGroup controlId="formKf" bsSize='small'>
                <Col sm={2} componentClass={ControlLabel}><Translate value='forma' />{':'}</Col>
                <Col sm={10} bsSize='small'>
                    <FormControl className='input-sm' componentClass="select" placeholder="select" defaultValue={kf} disabled={props.svod.isLoading}
                        onChange={(e) => setParam("kf", e.target.value)} bsSize='small' style={{ width: "auto" }}>
                        {props.nsi.sprform.filter(f => f.monthWithInc === 1).map(it => <option key={it.kf} value={it.kf}>{it.kf} {getNameLocalize(props.i18n, it)}</option>)}
                    </FormControl>
                </Col>
            </FormGroup>
            {errors.length > 0 ? <Row bsSize='small'>
                <Col sm={10} bsSize='small' smOffset={2}> <ul>{errors.map(m => <li className="text-danger"><strong>{I18n.t(m)}</strong></li>)}</ul></Col>
            </Row> : ""}
            {svodRunning === true ? <Row>
                <Col smOffset={2} sm={10}>
                    <div className='text-center'><img src="../images/loading.gif" alt={I18n.t('waitsvod')} width={20} />{' '}<strong><Translate value="waitsvod" /></strong></div>
                </Col>
            </Row> : ''}
            {svodRunning === false && successMessage != null ? <Row bsSize='small'>
                <Col smOffset={2} sm={10}>
                    <Alert bsStyle="success">{successMessage}</Alert>
                </Col>
            </Row> : ''}
            <FormGroup>
                <Col smOffset={2} sm={10}>
                    <Button className='btn btn-primary text-center' type="submit" disabled={props.svod.isLoading === true}><Translate value="execSvod" /></Button>
                </Col>
            </FormGroup>
        </Form>
        </>
}
export default connect(
    state => state,
    dispatch => bindActionCreators({ ...actionCreatorsNsi, ...actionCreatorsSvod }, dispatch)
)(SvodFormMonths);
import React, { useState, Fragment, useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { cloneDeep } from 'lodash';
//import * as FileSaver from 'file-saver';
import { Translate, I18n } from 'react-redux-i18n';
import { Button, FormGroup, Table, Modal, Row, Col, Checkbox, ControlLabel } from 'react-bootstrap';
import TableScrollbar from 'react-table-scrollbar';
//import * from 'bootstrap-icons';
//import { LinkContainer } from 'react-router-bootstrap';
//import NumberFormat from 'react-number-format';

import { actionCreators as actionFinPaidData } from '../../store/FinPaidData';
import { actionCreators as actionNsi } from '../../store/Nsi';
import { getNameLocalize } from '../Constants';

function FormsModal(props) {
    const [] = useState(() => {
        if (props.nsi.sprform.length === 0) props.getNs('sprform');
        return true;
    }, props.nsi.torg.length > 0);
    const [isAllForms, setIsAllForms] = useState(true);
    const [forms, setForms] = useState([]);
    useEffect(() => {
        if (props.showModal === false) {
            setForms([]);
            setIsAllForms(true);
        } else {
            //todo load avariable forms by org id
            const getForms = async (korg, year) => {
                let ret = await props.getFormsByOrg(korg, year);
                if (ret.successed === true) {
                    if (ret.isAllForms === true) {
                        setForms([]);
                        setIsAllForms(true);
                    } else {
                        setForms(ret.forms);
                        setIsAllForms(false);
                    }
                } else {
                    alert(ret.error);
                    setForms([]);
                    setIsAllForms(true);
                }
            }
            getForms(props.objKod, props.year);
        }
    }, [props.showModal, props.objKod, props.year]);
    const setAllForms = (check) => {
        if (check === true) setForms([]);
        setIsAllForms(check);
    }
    const checkForm = (ch, kf) => {
        let incl = forms.includes(kf);
        if (ch === true && incl === false) {
            let frms = cloneDeep(forms);
            frms.push(kf);
            setForms(frms);
        } else if (incl === true) {
            let frms = cloneDeep(forms);
            let ind = frms.indexOf(kf);
            frms.splice(ind, 1);
            setForms(frms);
        }
    }
    const commitBt = (e) => {
        e.preventDefault();
        //save forms by org id
        const saveForms = async () => {
            let ret = await props.setFormsByOrg({ year: props.year, kodOrg: props.objKod, isAllForms, forms });
            if (ret.successed === true) {
                props.commitBt(forms);
            } else {
                alert(ret.error);
            }
        }
        saveForms();
    }
    return props.showModal ? <Modal show={props.showModal} onHide={props.handleHide}
        size="sm"
        show={true}
        backdrop="static"
        keyboard={false}
        dialogClassName="modal-sm-dialog-scrollable"
        centered
    >
        <Modal.Body>
            <Row>
                <Col sm={12}><strong><Translate value="year" /></strong>{props.year}</Col>
            </Row>
            <Row>
                <Col sm={12}><strong><Translate value="org" /></strong>{props.obj.kod}&nbsp;{getNameLocalize(props.i18n, props.obj)}</Col>
            </Row>
            <Row>
                <Col sm={12}>
                    <Checkbox checked={isAllForms === true} onChange={(e) => setAllForms(e.target.checked)}><strong><Translate value="allForms" /></strong></Checkbox>
                </Col>
            </Row>
            {isAllForms != true ? <TableScrollbar height="400px">
                <Table striped bordered condensed hover>
                    <tbody>
                        {props.nsi.sprform.map(r => <tr key={r.kf} >
                            <td><Checkbox checked={forms.includes(r.kf)} onChange={(e) => checkForm(e.target.checked, r.kf)}>{/*</Checkbox></td>
                            <td>{r.kf}&nbsp;{getNameLocalize(props.i18n, r)}*/}{r.kf}&nbsp;{getNameLocalize(props.i18n, r)}</Checkbox></td>
                        </tr>)}
                    </tbody>
                </Table>
            </TableScrollbar> : ""}
        </Modal.Body>
        <Modal.Footer>
            <Button type="buttom" bsStyle="success" onClick={commitBt} disabled={isAllForms === false && forms.length===0}><Translate value="save" /></Button>
            <Button type="buttom" onClick={props.handleHide}><Translate value="cancel" /></Button>
        </Modal.Footer>
    </Modal> : ""
}
export default connect(
    state => state,
    dispatch => bindActionCreators({ ...actionFinPaidData, ...actionNsi }, dispatch)
)(FormsModal);
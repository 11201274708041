import React, { Fragment }/*, { useEffect}*/ from 'react';
//import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Translate/*, I18n*/ } from 'react-redux-i18n';

import './HomeMenu.css';
const HomeMenu = (props) => {
    //useEffect(() => {
    //    document.body.style.backgroundImage = "url('/images/home-background.png')";
    //    return () => {
    //        document.body.style.backgroundImage = "none";
    //    }
    //}, []);
    //style={{ backgroundImage: `url(${process.env.PUBLIC_URL +'/home-background.png'})`}}
    //style={{ backgroundImage: `url(${image})` }}
    let user = props.user;
    //let accessLevel = props.userInfo.user.accessLevel;// 1 – РК; 2 – область; 3 - район; 4 - организация;
    let isAdmins = false;
    //let isEditAdmins = false;
    let isAdminAdmins = false;
    //let isEditUsers = false;
    let isAdminEditData = false;
    let isEditFin = false;
    let isCommonNsi = false;
    let isUserEditData = false;
    let typeUser = undefined;
    let isSvod = undefined;
    let isAdmChildOrgs = false;
    let serverDtMonth = -1;
    let isReadOnLy = false;
    let dt = props.user.serverDt;
    if (dt instanceof Date)
        serverDtMonth = dt.getMonth() + 1;
    else if (typeof dt === "string" && dt.length > 0)
        serverDtMonth = new Date(dt).getMonth() + 1;
    if (user) {
        typeUser = user.typeUser
        for (var i in user.roles) {
            let role = user.roles[i];
            if (role === 'EditAdmins') {
                isAdminAdmins = true;
            }
            if (role === 'EditAdmins' ||
                role === 'EditUsers') {
                isAdmins = true;
                continue;
            }
            if (role === 'AdminEditData') {
                isAdminEditData = true;
            }
            if (role === 'CommonNsi') {
                isCommonNsi = true;
            }
            if (role === 'EditFin') {
                isEditFin = true;
            }
            if (role === 'UserEditData' || role === 'UserReadData') {
                isUserEditData = true;
            }
        }
        isSvod = typeUser !== 4;
        isReadOnLy = user.roles.filter(r => r === 'UserReadData').length > 0;
        isAdmChildOrgs = (user.nomenklKod === '008' || user.nomenklKod === '049' || user.nomenklKod === '043');//редактирование списка дочерних организаций ВА, СВА, ФП для ЦРБ
    } else
        isReadOnLy = true;
    return <Fragment>
        {isUserEditData === true || isAdminEditData === true ? <Fragment>
            <div className="cbox" style={{ height: user.accessLevel === 1 || user.accessLevel === 2 ? `${isReadOnLy === true ? '55mm' : '90mm'}`: '55mm', width: "84mm" }}>
                <p className="text-center h5"><strong><Translate value='work' /></strong></p>
                <ul>

                    <li><Link to={'/openForm'}><Translate value='openForm' /></Link></li>
                    <li><Link to={'/openFormPstr'}><Translate value='openFormPstr' /></Link></li>
                    <li><Link to={'/toexcel'}><Translate value='toExcel' /></Link></li>
                    {isReadOnLy != true && user.accessLevel === 2 ? <><li><Link to={'/statusForm'}><Translate value='statusForm.title' /></Link></li> 
                        <li><Link to={'/statusTableObl'}><Translate value='statusTable.title' /></Link></li></> : ""}
                    {isReadOnLy != true && (user.accessLevel === 1 && typeUser === 5) ? <><li><Link to={'/statusFormRk'}><Translate value='statusForm.title' /></Link></li>
                        <li><Link to={'/statusTableRk'}><Translate value='statusTable.title' /></Link></li></> : ""}
                    <li><Link to={'/inputInfor'}><Translate value='inputInfor' /></Link></li>
                    <li><Link to={'/inputInforForm'}><Translate value='InputInforForm.tit' /></Link></li>
                    <li><Link to={'/inputInforObj'}><Translate value='InputInforObj.tit' /></Link></li>
                    {isReadOnLy != true ? <li><Link to={'/fromexcel'}><Translate value='fromExcel' /></Link></li> : ""}
                    {isReadOnLy != true && (user.accessLevel === 2 || user.accessLevel === 3 || user.accessLevel === 4) ? <li><Link to={'/deleteForm'}><Translate value='deleteForm' /></Link></li> : ""}
                    {/*{isAdmChildOrgs === true ? <li><Link to={'/adminChildUsers'}><Translate value='childOrgs.users' /></Link></li> : ""}*/}
                    {user.accessLevel === 2 ? <li><Link to={'/reportfincrrz'}><Translate value='reportfincrrz' /></Link></li> : ""}
                    {isReadOnLy != true && (user.accessLevel === 1 || user.accessLevel === 2) ? <li><Link to={'/editOrgsOnObl'}><Translate value='editOrgsOnObl' /></Link></li> : ""}
                    {isReadOnLy != true && user.accessLevel === 2 ? <li><Link to={'/accessRazdOrg'}><Translate value='accessRazd.tit' /></Link></li> : ""}
                    {isReadOnLy != true && (user.accessLevel === 1 || user.accessLevel === 2) ? <li><Link to={'/logAudit'}><Translate value='audit.tit' /></Link></li> : ""}
                </ul>
            </div>
            {isReadOnLy != true ? <div className="cbox" style={{ height: "30mm", width: "80mm" }}>
                <p className="text-center h5"><strong><Translate value='control' /></strong></p>
                <ul>
                    <li><Link to={'/controlInForm'}><Translate value='controlInForm' /></Link></li>
                    <li><Link to={'/controlAllForm'}><Translate value='controlAllForm' /></Link></li>
                    <li><Link to={'/controlInYear'}><Translate value='controlInYear' /></Link></li>
                </ul>
            </div> : ""}
            {isReadOnLy != true && (user.accessLevel === 1 || user.accessLevel === 2) ? <div className="cbox" style={{ height: "30mm", width: "80mm" }}>
                <p className="text-center h5"><strong><Translate value='gen47' /></strong></p>
                <ul>
                    <li><Link to={'/gen47'}><Translate value='gen47' /></Link></li>
                    <li><Link to={'/gen47OrgParam'}><Translate value='gen47Param' /></Link></li>
                </ul>
            </div> : ""}
            {isReadOnLy != true ? <div className="cbox" style={user.accessLevel === 4 ? { height: "45mm", width: "80mm" } : { height: "85mm", width: "80mm" }}>
                <p className="text-center h5"><strong><Translate value='svod' /></strong></p>
                <ul>
                    <li><Link to={'/svodFormQuarts'}><Translate value='svodFormQuart' /></Link></li>
                {isSvod === true ? <Fragment>
                     {user.accessLevel !== 4 ? <li><Link to={'/svodForm'}><Translate value='svodForm' /></Link></li> : ""}
                        <li><Link to={'/svodFormRaz'}><Translate value='svodFormRaz' /></Link></li>
                        {user.accessLevel < 4 ? <li><Link to={'/svodFormOrgRaz'}><Translate value='svodFormOrgRaz.tit' /></Link></li> : ""}
                        <li><Link to={'/editFormRaz'}><Translate value='editFormRaz' /></Link></li>
                        {user.accessLevel < 4 ? <li><Link to={'/editFormOrgRaz'}><Translate value='editFormOrgRaz' /></Link></li> : ""}
                        <li><Link to={'/editFormPeriod'}><Translate value='editFormPeriod' /></Link></li>
                        {isAdmChildOrgs === true ? <li><Link to={'/svodSubOrg'}><Translate value='svodSub' /></Link></li> : ""}
                        {user.accessLevel === 2 ? <li><Link to={'/svodSubOrgObl'}><Translate value='svodSub' /></Link></li> : ""}
                        {user.accessLevel < 4 ? <li><Link to={'/svodSpecificOrg'}><Translate value='svodSpecificOrg.tit' /></Link></li> : ""}
                    </Fragment> : ""}
                    <li><Link to={'/svodFormMonths'}><Translate value='svodFormMonths' /></Link></li>
                    {((user.accessLevel === 4 || isAdmChildOrgs === true) && serverDtMonth === 1) ? <li><Link to={'/copy12MonthYoYear'}><Translate value='copy12MonthYoYear' /></Link></li> : ""}
                </ul>
            </div> : ""}
            <div className="cbox" style={{ height: "25mm", width: "80mm", backgroundColor: "#bae8e8" }}>
                <p className="text-center h5" style={{ color: "darkred", fontWeight:"bold", fontSize:"12pt"}}><strong><Translate value='medinfoTitle' /></strong></p>
                <ul>
                    <li><Link to={'/genMedinfoTable2'}><Translate value='generateTableMedinfo' /></Link></li>
                    <li><Link to={"/MapSvg"}><Translate value='indicatorsMap.title' /></Link></li>
                </ul>
            </div>
        </Fragment> : ""}
        {isAdmins === true || isCommonNsi === true || isAdminEditData === true /*|| accessLevel === 2*/ /*|| isAdmChildOrgs === true*/ ? <div className="cbox" style={{ height: "40mm", width: "80mm" }}>
            <p className="text-center h5"><strong><Translate value='adm' /></strong></p>
            <ul>
                {isAdmins === true ? <li><Link to={'/adminUsers'}><Translate value='adm' /> {isAdminAdmins === true ? <Translate value='adminAdmins' /> : <Translate value='adminUsers' />}</Link></li> : ""}
                {isAdminEditData && user.accessLevel === 1 ? <Fragment>
                    <li><Link to={'/nsi'}><Translate value='nsi' /></Link></li>
                    <li><Link to={'/editTemplateFiles'}><Translate value='templateExcel.title' /></Link></li>
                    <li><Link to={'/editcontrol'}><Translate value='editcontrol' /></Link></li>
                    <li><Link to={'/closingPeriod'}><Translate value='closingPeriod' /></Link></li>
                    <li><Link to={'/adminEditChildOrg'}><Translate value='childOrgs.title' /></Link></li>
                </Fragment> : ""}
                {/*{isAdmChildOrgs === true ? <li><Link to={'/adminChilsOrgs'}><Translate value='childOrgs.title' /></Link></li> : ""}*/}
            </ul></div> : ""}
        {isAdminEditData && user.accessLevel === 1 ? <div className="cbox" style={{ height: "50mm", width: "80mm" }}>
            <p className="text-center h5"><strong><Translate value='medinfoTitle' /> <Translate value='nsi' /></strong></p>
            <ul>
                <li><Link to={'/editMF'}><Translate value='editFormulMedinfo' /></Link></li>
                <li><Link to={'/editBokMkb'}><Translate value='editBokMkbMedinfo' /></Link></li>
                <li><Link to={'/editBokDr'}><Translate value='editBokDrMedinfo' /></Link></li>
                <li><Link to={'/editParts'}><Translate value='editPartsMedinfo' /></Link></li>
            </ul></div> : ""}
        {isEditFin === true ? <div className="cbox" style={{ height: "35mm", width: "80mm" }}>
            <p className="text-center h5"><strong><Translate value='adm' /></strong></p>
            <ul>
                <li><Link to={'/fin'}><Translate value='fin' /></Link></li>
                <li><Link to={'/reportfin'}><Translate value='reportfin' /></Link></li>
                <li><Link to={'/repSubordinates'}><Translate value='orgSubordinates' /></Link></li>
            </ul></div> : ""}

    </Fragment>
};
export default HomeMenu;
//export default connect(state => state.userInfo)(HomeMenu);

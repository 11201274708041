import React, { Fragment } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Nav, NavDropdown, MenuItem/*, NavItem*/, Navbar } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { Translate, I18n } from 'react-redux-i18n';
//import { Link } from 'react-router-dom';
import { actionCreators as actionUserInfo } from '../store/UserInfo';
//import { actionCreators as actionCreatorsNsi } from '../store/Nsi';

//import './NavMenu.css';
const NavMainMenu = props => {
    let user = props.userInfo.user;
    //let accessLevel = props.userInfo.user.accessLevel;// 1 – РК; 2 – область; 3 - район; 4 - организация;
    let isAdmins = false;
    //let isEditAdmins = false;
    let isAdminAdmins = false;
    //let isEditUsers = false;
    let isAdminEditData = false;
    let isEditFin = false;
    let isCommonNsi = false;
    let isUserEditData = false;
    let typeUser = undefined;
    let isSvod = undefined;
    let isAdmChildOrgs = false;
    let serverDtMonth = -1;
    let isReadOnLy = false;
    let dt = props.userInfo.user.serverDt;
    if (dt instanceof Date)
        serverDtMonth = dt.getMonth() + 1;
    else if (typeof dt === "string" && dt.length > 0)
        serverDtMonth = new Date(dt).getMonth() + 1;
    if (user) {
        typeUser = user.typeUser
        for (var i in user.roles) {
            let role = user.roles[i];
            if (role === 'EditAdmins') {
                isAdminAdmins = true;
            }
            if (role === 'EditAdmins' ||
                role === 'EditUsers') {
                isAdmins = true;
                continue;
            }
            if (role === 'AdminEditData') {
                isAdminEditData = true;
            }
            if (role === 'CommonNsi') {
                isCommonNsi = true;
            }
            if (role === 'EditFin') {
                isEditFin = true;
            }
            if (role === 'UserEditData' || role === 'UserReadData') {
                isUserEditData = true;
            }
        }
        isReadOnLy = user.roles.filter(r => r === 'UserReadData').length > 0;
        isSvod = typeUser !== 4;
        isAdmChildOrgs = (user.nomenklKod === '008' || user.nomenklKod === '049' || user.nomenklKod === '043');//редактирование списка дочерних организаций ВА, СВА, ФП для ЦРБ
    } else
        isReadOnLy = true;
   /* if (isUserEditData === true || isAdminEditData === true) {
        if (props.nsi.allsprtab.length === 0) {
            props.getNs('allsprtab');
        }
        if (props.nsi.sprform.length === 0) {
            props.getNs('sprform');
        }
        if (props.nsi.periods.length === 0) {
            props.getNs('periods');
        }
        if (props.nsi.sprraz.length === 0) {
            props.getNs('sprraz');
        }
    }
    */
    // let admTitle = I18n.t('adm');
    // console.info(I18n.t('work'));
    let workTitle = I18n.t('work');
    let finTitle = I18n.t('finTitle');
    let medinfoTitle = I18n.t('medinfoTitle');
    return (<Nav className="justify-content-end">
        {isAdmins === true || isCommonNsi === true || isAdminEditData === true /*|| accessLevel === 2*/ /*|| isAdmChildOrgs===true*/ ?
            <NavDropdown title={I18n.t('adm')} id="basic-nav-dropdown-1">
                {isAdmins === true ? <LinkContainer to={'/adminUsers'} exact><MenuItem><Translate value='adm' />  {isAdminAdmins === true ? <Translate value='adminAdmins' /> : <Translate value='adminUsers' />}</MenuItem></LinkContainer> : undefined}
                {isAdminEditData === true && user.accessLevel === 1 ? <Fragment>
                    <LinkContainer to={'/nsi'} exact><MenuItem><Translate value='nsi' /></MenuItem></LinkContainer>
                    <LinkContainer to={'/editTemplateFiles'} exact><MenuItem><Translate value='templateExcel.title' /></MenuItem></LinkContainer>
                    <LinkContainer to={'/editcontrol'} exact><MenuItem><Translate value='editcontrol' /></MenuItem></LinkContainer>
                    <LinkContainer to={'/closingPeriod'} exact><MenuItem><Translate value='closingPeriod' /></MenuItem></LinkContainer>
                    <LinkContainer to={'/adminEditChildOrg'} exact><MenuItem><Translate value='childOrgs.title' /></MenuItem></LinkContainer>
                </Fragment> : undefined}
                {/*user.accessLevel === 2 ? <LinkContainer to={'/closingOrgPeriod'} exact><MenuItem><Translate value='closingOrgPeriod' /></MenuItem></LinkContainer> : undefined*/}
                {/*{isAdmChildOrgs === true ? <LinkContainer to={'/adminChilsOrgs'} exact><MenuItem><Translate value='childOrgs.title' /></MenuItem></LinkContainer> : undefined}*/}
                {/*{isAdmChildOrgs === true ? <LinkContainer to={'/adminChildUsers'}><Translate value='childOrgs.users' /></LinkContainer> : undefined}*/}
            </NavDropdown> : ''}
        {isUserEditData === true || isAdminEditData === true ? <NavDropdown title={workTitle} id="basic-nav-dropdown-2">
            <LinkContainer to={'/openForm'}><MenuItem><Translate value='openForm' /></MenuItem></LinkContainer>
            <LinkContainer to={'/openFormPstr'}><MenuItem><Translate value='openFormPstr' /></MenuItem></LinkContainer>
            <LinkContainer to={'/toexcel'}><MenuItem><Translate value='toExcel' /></MenuItem></LinkContainer>
            {isReadOnLy != true && user.accessLevel === 2 ? <><LinkContainer to={'/statusForm'}><MenuItem><Translate value='statusForm.title' /></MenuItem></LinkContainer>
                <LinkContainer to={'/statusTableObl'}><MenuItem><Translate value='statusTable.title' /></MenuItem></LinkContainer></>: ''}
            {isReadOnLy != true && (user.accessLevel === 1 && typeUser === 5)? <><LinkContainer to={'/statusFormRk'}><MenuItem><Translate value='statusForm.title' /></MenuItem></LinkContainer>
                <LinkContainer to={'/statusTableRk'}><MenuItem><Translate value='statusTable.title' /></MenuItem></LinkContainer></> : ''}
            <LinkContainer to={'/inputInfor'}><MenuItem><Translate value='inputInfor' /></MenuItem></LinkContainer>
            <LinkContainer to={'/inputInforForm'}><MenuItem><Translate value='InputInforForm.tit' /></MenuItem></LinkContainer>
            <LinkContainer to={'/inputInforObj'}><MenuItem><Translate value='InputInforObj.tit' /></MenuItem></LinkContainer>
            {isReadOnLy != true ? <LinkContainer to={'/fromexcel'}><MenuItem><Translate value='fromExcel' /></MenuItem></LinkContainer> : ""}
            {isReadOnLy != true && (user.accessLevel === 2 || user.accessLevel === 3 || user.accessLevel === 4) ? <LinkContainer to={'/deleteForm'}><MenuItem><Translate value='deleteForm' /></MenuItem></LinkContainer> : ''}
            {user.accessLevel === 2 ? <LinkContainer to={'/reportfincrrz'}><MenuItem><Translate value='reportfincrrz' /></MenuItem></LinkContainer> : ''}
            {isReadOnLy != true && (user.accessLevel === 1 || user.accessLevel === 2) ? <Fragment>
                <LinkContainer to={'/editOrgsOnObl'}><MenuItem><Translate value='editOrgsOnObl' /></MenuItem></LinkContainer>
                {isReadOnLy != true && user.accessLevel === 2 ? <LinkContainer to={'/accessRazdOrg'}><MenuItem><Translate value='accessRazd.tit' /></MenuItem></LinkContainer> : ''}
                <LinkContainer to={'/logAudit'}><MenuItem><Translate value='audit.tit' /></MenuItem></LinkContainer>
                <li role="separator" className="divider"></li>
                    <li className="dropdown-header"><Translate value='gen47' /></li>
                    <LinkContainer to={'/gen47OrgParam'}><MenuItem><Translate value='gen47Param' /></MenuItem></LinkContainer>
                    <LinkContainer to={'/gen47'}><MenuItem><Translate value='gen47' /></MenuItem></LinkContainer>
                </Fragment>
                : ''}
            <li role="separator" className="divider"></li>
            {isReadOnLy != true ? <><li className="dropdown-header"><Translate value='control' /></li>
                <LinkContainer to={'/controlInForm'}><MenuItem><Translate value='controlInForm' /></MenuItem></LinkContainer>
                <LinkContainer to={'/controlAllForm'}><MenuItem><Translate value='controlAllForm' /></MenuItem></LinkContainer>
                <LinkContainer to={'/controlInYear'}><MenuItem><Translate value='controlInYear' /></MenuItem></LinkContainer></> : ""}
            {isReadOnLy != true ? <Fragment> <li role="separator" className="divider"></li>
                <li className="dropdown-header"><Translate value='svod' /></li>
                <LinkContainer to={'/svodFormQuarts'}><MenuItem><Translate value='svodFormQuart' /></MenuItem></LinkContainer></Fragment> : ""}
            {isReadOnLy != true && isSvod === true ? <Fragment>
                {props.userInfo.user.accessLevel !== 4 ? <LinkContainer to={'/svodForm'}><MenuItem><Translate value='svodForm' /></MenuItem></LinkContainer> : ''}
                <LinkContainer to={'/svodFormRaz'}><MenuItem><Translate value='svodFormRaz' /></MenuItem></LinkContainer>
                {user.accessLevel < 4 ? <LinkContainer to={'/svodFormOrgRaz'}><MenuItem><Translate value='svodFormOrgRaz.tit' /></MenuItem></LinkContainer> : ""}
                <LinkContainer to={'/editFormRaz'}><MenuItem><Translate value='editFormRaz' /></MenuItem></LinkContainer>
                {user.accessLevel < 4 ? <LinkContainer to={'/editFormOrgRaz'}><MenuItem><Translate value='editFormOrgRaz' /></MenuItem></LinkContainer> : ""}
                <LinkContainer to={'/editFormPeriod'}><MenuItem><Translate value='editFormPeriod' /></MenuItem></LinkContainer>
                {isAdmChildOrgs === true ? <LinkContainer to={'/svodSubOrg'}><MenuItem><Translate value='svodSub' /></MenuItem></LinkContainer> : ''}
                {props.userInfo.user.accessLevel === 2 ? <LinkContainer to={'/svodSubOrgObl'}><MenuItem><Translate value='svodSub' /></MenuItem></LinkContainer> : ''}
                {user.accessLevel < 4 ? <LinkContainer to={'/svodSpecificOrg'}><MenuItem><Translate value='svodSpecificOrg.tit' /></MenuItem></LinkContainer> : ""}
                <LinkContainer to={'/svodFormMonths'}><MenuItem><Translate value='svodFormMonths' /></MenuItem></LinkContainer>
                {isReadOnLy != true && ((user.accessLevel === 4 || isAdmChildOrgs === true) && serverDtMonth === 1) ? <LinkContainer to={'/copy12MonthYoYear'}><MenuItem><Translate value='copy12MonthYoYear' /></MenuItem></LinkContainer> : ''}
            </Fragment> : undefined}
        </NavDropdown> : undefined}
        {isEditFin === true ? <NavDropdown title={finTitle} id="basic-nav-dropdown-3">
            <LinkContainer to={'/fin'}><MenuItem><Translate value='fin' /></MenuItem></LinkContainer>
            <LinkContainer to={'/reportfin'}><MenuItem><Translate value='reportfin' /></MenuItem></LinkContainer>
            <LinkContainer to={'/repSubordinates'}><MenuItem><Translate value='orgSubordinates' /></MenuItem></LinkContainer>
        </NavDropdown> : undefined}
        {isUserEditData === true || isAdminEditData === true ? <NavDropdown title={medinfoTitle} id="basic-nav-dropdown-4">
            {isAdminEditData === true && props.userInfo.user.accessLevel === 1 ?
                <Fragment>
                    <LinkContainer to="/editMF" exact><MenuItem><Translate value='editFormulMedinfo' /></MenuItem></LinkContainer>
                    <LinkContainer to="/editBokMkb" exact><MenuItem><Translate value='editBokMkbMedinfo' /></MenuItem></LinkContainer>
                    <LinkContainer to="/editBokDr" exact><MenuItem><Translate value='editBokDrMedinfo' /></MenuItem></LinkContainer>
                    <LinkContainer to="/editParts" exact><MenuItem><Translate value='editPartsMedinfo' /></MenuItem></LinkContainer>
                </Fragment> : undefined}
            <LinkContainer to="/genMedinfoTable2" exact><MenuItem><Translate value='generateTableMedinfo' /></MenuItem></LinkContainer>
            <LinkContainer to="/MapSvg" exact><MenuItem><Translate value='indicatorsMap.title' /></MenuItem></LinkContainer>
        </NavDropdown> : ''}
        <Navbar.Brand><a href="/downloads/instruction.pdf" style={{ fontSize: '14px' }} download><Translate value='instruction'></Translate></a></Navbar.Brand>
    </Nav>);
}
/*
 *  <LinkContainer to="/genMedinfoTable" exact><MenuItem><Translate value='generateTableMedinfo' /></MenuItem></LinkContainer>
 *  
 *  <Navbar.Text><Navbar.Link href="/downloads/instruction.docx" download><Translate value='instruction'></Translate></Navbar.Link></Navbar.Text>
        <Navbar.Brand><a href="/downloads/instruction.docx" style={{ fontSize: '14px' }} download><Translate value='instruction'></Translate></a></Navbar.Brand>

 */
export default connect(
    state => state/*.userInfo*/,
    dispatch => bindActionCreators(/*{ ...*/actionUserInfo/*, ...actionCreatorsNsi }*/, dispatch)
)(NavMainMenu);